.basic-button {
    color: white;
    border-color: #F1582E;
    background-color: #F1582E;
    font-size: 16px;
    border-radius: 50px;
    
}
.basic-button:hover, .basic-button:focus {
    color: white;
    background-color: #F1582E;
    border-color: #F1582E;
}